@media(min-width: 600px){

}
$breakpoint-up: (
    "medium": "40em",
    "large":"64em",
    "xlarge": "87.5em",
);
$breakpoint-down:(
    "small": "39.9375em",
    "medium": "63.9375em",
    "large": "87.4375em"
);
@mixin  breakpoint-up($size) {
    @media (min-width: map-get($breakpoint-up, $size)) {
        @content;
    }
}
@mixin  breakpoint-down($size) {
    @media (max-width: map-get($breakpoint-down, $size)) {
        @content;
    }
}