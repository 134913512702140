@use '../util' as *;
.container{
    height: 51rem;
    margin-bottom: 2rem;
}
.hero{
   position:absolute;
   height: 100vh;
    display: flex;
   width: calc(100vw - 1.1rem);
    padding-left:1rem;
    margin-bottom: 3rem;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    transform: scale(.8);
    transition: 0.5s ease-in-out;

    &-arrow-left, &-arrow-right{
        display: flex;
        position: absolute;
        font-size: var(--font-xlg);
        top: 50%;
        bottom: 50%;
        transform: translate(3%, 50%);
        justify-content: center;
        align-items: center;
        background-color: rgba(240, 248, 255, 0.452);
        border-radius: 15%;
        // padding: .5rem;
        cursor: pointer;
        width: 50px;
        height: 50px;
    }
    &-arrow-left{
        left: 15px
    }
    &-arrow-right{
        right: 15px;
    }
    &-active{
        opacity: 1;
        transform: scale(1);
        pointer-events: visible;
    }
   
    &_right{
        flex: 2;
        order: 2;
        padding-top: 1rem ;
      
       
        &_image{
            position: relative;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            height: 40rem;
        }
       
        
    }
    &_left{
        order: 1;
        flex: 2;
        margin: auto;
        text-align: left;
        justify-content: center;
        .title{
            letter-spacing: .7em;
            
        }
        h1{
            font-size: 4.5rem;
            font-family: var(--font-roboto);
            font-weight: 600;
        }
        p{
            margin: 2rem 5rem 2rem 0;
            padding-right: 2rem;
            font-size: var(--font-mid);
           
           
        }
       
    }
    &_button{
        background-color: var(--wine);
        border: var(--wine);
        color:var(--white)
    }
    &_paginate{
        position: absolute;
        bottom: -25%;
        left:50%;
        transform: translate(-50%, 0);
    }
}
.pagination_dot{
    display: inline-block;
    margin-left: 10px;
    cursor: pointer;
    height: 10px;
    width: 10px;
    background-color: #fff;
    border-radius: 50%;
}
.pagination_dot:hover{
transform: scale(1.2);
}
.pagination_dot-active{
background-color: steelblue;
}
//Tablet styling
@include breakpoint-down(medium){
    .hero{
        position: absolute;
        display: block;
        &_right{
            &_image{
                opacity: .5;
            }
        }
        &_left{
           transform: translateY(-100%);
           padding: 3rem;
           margin:0  2rem;
        }
    }
}

