:root{

    --font-med: 1.125rem;
    --font-mid: 1.32rem;
    --font-sm: 0.875rem;
    --font-lg:2.25rem;
    --font-xlg: 3.25rem;
    //font styles
    --font-roboto: "Roboto", sans-serif;
    --font-montserrat: "Montserrat", sans-serif;
}
