@use "../util/" as *;

.header{
   background-color: var(--black);
  
}
.nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 2rem 0;
    border-bottom: solid 1px white;
    &_logo{
        font-size: var(--font-lg);  
        font-weight:  bold;
        color: white;
    }
    &-icon{
        color: white;
        font-size: var(--font-mid);
    }
    &_links{
        display:flex;
       
        a{
            text-align: center;
            align-items: center;
            position:relative;
            margin-left: 5px;
            padding: 10px;
            color: white;
            &::after{
                content: "";
                display: block;
                background: var(--red);
                width: inherit;
                margin-top: 10px;
                height:5px;
                opacity: 0;
                transition: opacity 300ms ease-in-out;
            }
            &:hover{
                color: var(--cyan);
                &::after{
                    opacity: 1
                }
            }
        }
    }
}

