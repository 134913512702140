:root{
    --gray: #F2F2F2;
    --red: #D92525;
    --green: #158787;
    --brown:#591C21;
    --wine:#8C1F28;
    --black: #080808c0;
    --white: #ffffff;
    --cyan: #2bb7da;
}

