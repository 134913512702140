@use '../util/' as *;

*,*::before,*::after{
    box-sizing: border-box;
}
body, html{
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    // background-color:var(--black);
}
li{
    list-style: none
    
}
a{text-decoration: none;}
h1,h2,h3,h4,h5,h6{
    margin: 0;
    padding: 0;
}
button{
    padding: 1rem 2rem;
    border-radius: 0;

}


.hide-for-desktop{
    @include breakpoint-up(medium){
        display: none;
    }
}
.hide-for-mobile{
    @include breakpoint-down(small){
        display: none;
    }
}

