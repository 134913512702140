@use "../util/" as *;
.about{
    display: flex;
    justify-content: space-between;
    background-color: var(--gray);
    &_right{
        
        flex: 1;
        align-items: center;
        margin: auto auto;
        strong{
            color: var(--red);
        }
    }
    &_left{
        flex: 1;
        img{
            border-radius: 5px;
            width: 80%;
            height: 80%;
        }
    }
}