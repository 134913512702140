.carousel{
   
    cursor: grab;
    overflow: hidden;
    margin: 2%;

    &-inner{
        display: flex;
        height: 31rem;
    }
   
    &-item{
        min-height: 40rem;
        padding: 40px;
        min-width: 30rem;
        .overlay{
            position: absolute;
           display: flex;
            background-color: rgba(0, 0, 0, 0.27);
            width: 20rem;
           height: 28rem;
            border-radius: 2rem;
            .button{
                height: 4rem;
                font-size: var(--font-mid);
               margin:  auto;
               opacity: .8;
               background-color: var(--wine);
               color: var(--white);
               &:hover{
                transform: scale(1.1);
                transition: ease-in-out 300ms;
               }
            }
        }
        img{
            // position: absolute;
            pointer-events:none;
            width: 80%;
            height: 80%;
            border-radius: 2rem;
        }
    }
}
.heading{
    font-size: var(--font-lg);
}
