@use '../util/' as *;

.display{
    margin-bottom: 2rem;
    ul{
        display: flex;
        margin: 8% 0%;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        padding: 0;
        img{
            width: 100px;
            height: 100px;
           transform: translate(0% ,-50%);
           border-radius: 5px;

        }
        li{
            h3{
                font-size: var(--font-mid);
            }
            p{
                font-size: var(--font-med);
            }
            button {
               background-color: var(--white);
               border: 1px solid var(--black);
                border-radius: 5px;
                height:12.5rem;
                width: 20rem;
                &:hover{
                    color:var(--wine);
                    border: 3px solid var(--wine);
                }

            }
            
            color: var(--red);
        }
    }
}
//Tablet styling
@include breakpoint-down(medium){
    .display{
        justify-content: space-between;
        ul{
            display: flex;
            li{
                button{
                    flex-basis: 50%;
                    width: 10rem;
                }

            }
        }
    }
}