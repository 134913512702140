@use '../util/' as *;
.feature{
   
    background-color: var(--gray);
    color: var(--white);
   &_1,&_2{
    padding: 1rem;
   }
    &_1, &_2, &_3{
        display: flex;
        flex-direction: row wrap;
        align-items: center;
        justify-content: center;
        margin-bottom: 2rem;
        border-radius: 5px;
        h2{
            margin-bottom: 1rem;
        }
    }
    &_1{
        background-color: var(--green);
    }
    &_1_right, &_2_right{

        margin-left: 5rem;
    }
    &_1_right, &_2_right, &_3_right{
        flex: 1;
        width: 100%;
        img{
             border-radius: 5px;
             height:20rem;
             object-fit: cover;
             width: 80%;
           
         }
    }
    &_1_left, &_2_left, &_3_left{
        order: 1;
        flex: .5;
        border-radius: 5px;
        p{
            width: 400px;
        }
    
    }
    &_2{
        background-color: var(--brown);
        &_right{
            order: 2;
        }
        &_left{
           
            margin-left: 5rem;
           
        }
    }
    &_3{
       

        &_right, &_left{
            border-radius: 5px;
            img{
                border-radius: 5px;
                width: 90%;
            }
        }
        // &_right{
        //     background-color: var(--black);
        // }
        &_left{
            padding: 8.5%;
           background-color: var(--green
           );
        }
       
    }
   
}
.new{
    background-color: var(--gray
    );
    img{

        height: 300px;
        width: 400px;
    }
    &_left{
        flex: 1;
        width: 400px;
        padding: 2rem;
    }
}
.buttons{
    display: flex;
    .counter{
        margin: 1rem;
        padding: 1rem;
        height: 50px;
    }
    .result{
        padding: 1.5rem;
        background-color: var(--gray);

    }
}
@include breakpoint-down(medium){
    .feature{
        &_1{
           display: block; 
           &_left{
               padding: 2rem;
               
               p,h2{
                   margin: 2rem auto;
                text-align: center;
            }
            .button{
                margin-inline: 40%;
            }

           }
        }
        &_2{
           
            &_2_left{
               padding: 0;
               .buttons{
                .counter{

                    button{
   
                        &:hover{
                         color:var(--wine);
                         border: 3px solid var(--wine);
                    }
                   }
                }
               }
            }
        }
    }
}