@use '../util/' as *;
.footer{
    background-color:var(--black);
    color:white;
    display: flex;
    flex-flow: column wrap;
    padding: 3rem 0;
   
   
    &_nav, &_text{
        display:flex;
        justify-content:space-between;
       
       
    }
    &_text{
        margin-bottom: 1rem;
        &_desciption{
            width: 45%;
        }
        &_socials{
            ul{
                display:flex;
                list-style-type: none;
                margin-left: 5px;
                li{
                    padding-left: 1rem;
                }

            }
        }
    }
   
}