@use "../util" as *;
.collection{
    background-color: var(--white);
    color: var(--white);
    ul{
        margin: 0;
        padding: 0;
        list-style-type: none;
        // background-color: var(--green);
        gap: 2rem;
        span{
            height: 2rem;
        }
        li{
            display: flex;
            flex-wrap: row wrap;
            padding-top: 5rem;
            gap: 4rem;
            justify-content: space-between;
            align-items: center;
            border-radius: 15px;
        
            figure{
                width: 50%;
                height: 300px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                box-shadow: 0 0 8px 8px var(--black);   
            }
            h1{
                font-size: var(--font-xlg);
                
            }
            p{
                padding: 2rem 0;
                margin-right: 2rem;
            }
            .figure-button{
                background-color: var(--black);
                color: var(--white);
            }
        }
    }
    .reverse{
        background-color: var(--wine);
        margin-top: 2rem;
        figure{
            order: 2;
        }
        text{
            order: 1;
        }
    }
}
.item1{
    background-image: url("../../assets/images/cakes/cupcakes/pexels-ehioma-osih-11321141.jpg");
}